<template>
  <VueContentHolder id="divProfitByCategoryWrapper">
    <BrandResultView
      @handleSubmit="submitResultPopUp"
      @close="closeModal"
      :key="showResultView"
      :showComponent="showResultView"
      :topTitle="resultViewItems.topTitle"
      :bottomTitle="resultViewItems.bottomTitle"
      :topValue="resultViewItems.topValue"
      :bottomValue="resultViewItems.bottomValue"
    ></BrandResultView>
    <Header :text="headerContent[0].text" :headline="headerContent[0].headline"></Header>
    <VueSpacer level="20" />
    <VueForm :bodyPadding="['0', '20']" ref="form">
      <VueInput
        name="marlboro"
        id="marlboro"
        type="text"
        placeholder="Alış Fiyatınız"
        v-model="formModel.priceOfPurchase"
        :messageOnTop="true"
        validationRules="required|decimal"
        validatorName="Alış fiyatı"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="parliament"
        id="parliament"
        type="text"
        placeholder="Satış Fiyatınız"
        v-model="formModel.priceOfSales"
        :messageOnTop="true"
        validationRules="required|decimal"
        validatorName="Satış fiyatı"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="chesterfield"
        id="chesterfield"
        type="text"
        placeholder="Satış Adedi"
        v-model="formModel.numberOfSales"
        :messageOnTop="true"
        validationRules="required|numeric"
        validatorName="Satış adedi"
      ></VueInput>
      <VueSpacer level="20" />
    </VueForm>
    <VueSpacer level="60" />
    <BrandButton
      v-if="!showResultView"
      :size="sizes.xxLarge"
      :contentAlignment="constants.flexAlignment.center"
      type="submit"
      :padding="{ vertical: 15, horizontal: 0 }"
      @click="handleSubmit"
      >HESAPLA</BrandButton
    >
  </VueContentHolder>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import Header from '@/components/brand/Headers/Header.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandResultView from '@/components/brand/BrandResultView/BrandResultView.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'EndorsementByCategory',
  mixins: [gtmUtils],
  components: {
    Header,
    VueForm,
    VueInput,
    BrandButton,
    VueContentHolder,
    VueSpacer,
    BrandResultView,
  },
  data() {
    return {
      resultViewItems: {
        topTitle: 'Cironuz',
        topValue: 0,
        bottomTitle: 'Kârınız',
        bottomValue: 0,
      },
      showResultView: false,
      formModel: {
        priceOfPurchase: '',
        priceOfSales: '',
        numberOfSales: '',
      },
      headerContent: [
        {
          headline: 'Hangi üründen ne kadar kâr ediyorsunuz?',
          text: 'Lütfen sattığınız ürünün alış ve satış fiyatlarını giriniz.',
        },
      ],
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    calculateTotalRevenueAndProfit() {
      let pop = this.formModel.priceOfPurchase.replace(',', '.');
      let pos = this.formModel.priceOfSales.replace(',', '.');
      let nos = this.formModel.numberOfSales;
      this.resultViewItems.bottomValue = (pos - pop) * nos;
      this.resultViewItems.topValue = pos * nos;
      this.pushDataLayerEvent('learn', {
        event: 'product_profit_calculation',
        sales_quantity: nos,
        buying_amount: pop,
        selling_amount: pos,
        total_profit: this.resultViewItems.bottomValue,
        total_revenue: this.resultViewItems.topValue,
      });
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.calculateTotalRevenueAndProfit();
        this.showResultView = true;
        if (this.showResultView) {
          document.documentElement.style.overflow = 'hidden';
        }
      }
    },
    closeModal() {
      this.showResultView = false;
      if (!this.showResultView) {
        document.documentElement.style.overflow = 'auto';
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}/`);
    },
  },
};
</script>

<style lang="scss"></style>
